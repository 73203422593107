<template>
  <div class="home-protocol">
    <div class="container">
      <header class="home-protocol__header">
        <base-title type="h2" big>{{ t(`${baseT}title`) }}</base-title>
      </header>
      <div class="home-protocol__wrapper">
        <div class="home-protocol__block row gy-4">
          <div class=" col-lg-5">
            <base-title type="h3">
              <span>{{ t(`${baseT}subtitle_1`) }}</span>
              {{ t(`${baseT}subtitle_2`) }}
            </base-title>
            <div class="home-protocol__desc">{{ t(`${baseT}desc`) }}</div>
          </div>
          <div class="home-protocol__image col-lg-6 offset-lg-1">
            <img :src="imageAdvantagePos" alt="advantage pos">
          </div>
        </div>
        <div class="row gy-4">
          <div class="col-md-5 order-last order-md-first">
            <div class="home-protocol__info">
              <div class="home-protocol__name">{{ t(`${baseT}name`) }}</div>
              <div class="home-protocol__definition">{{ t(`${baseT}definition`) }}</div>
            </div>
          </div>
          <div class="home-protocol__more col-md-6 offset-md-1">
            <p>{{ t(`${baseT}desc_1`) }}</p>
            <p>{{ t(`${baseT}desc_2`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'
import imageAdvantagePos from '@/assets/advantage-pos.png'

const {t} = useI18n()
const baseT = 'home.protocol.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-protocol {
  &__header {
    text-align: center;
    margin-bottom: 124px;

    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 0;

    &:after, &:before {
      position: absolute;
      content: '';
      background-size: cover;
      z-index: -1;
    }

    &:after {
      width: 42.88%;
      padding-top: 32.83%;
      bottom: -11%;
      left: -12%;
      background-image: url("~@/assets/shadow-primary.svg");
      opacity: .6;
      filter: blur(90px);
    }

    &:before {
      width: 49.29%;
      padding-top: 47.47%;
      top: -15%;
      right: -39%;
      background-image: url("~@/assets/advantage-shadow-one.svg");
      filter: blur(90px);
    }
  }

  &__block {
    margin-bottom: $row * 3;

    span {
      color: #6153DB;
      font-weight: 500;
    }
  }

  &__desc {
    margin-top: $row * 1.5;
    font-size: 24px;
    line-height: 34px;
  }

  &__info {
    background-color: #FFFFFF;
    box-shadow: 0 4.60703px 30.9329px -5.26518px rgba(45, 74, 136, 0.24);
    border-radius: 29px;
    padding: 24px 30px;
  }

  &__name {
    font-weight: 600;
  }

  &__more {
    font-size: 24px;
    line-height: 34px;

    p:last-child {
      font-weight: 500;
    }
  }

  @media (max-width: 991px) {
    &__image {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 575px) {
    &__header {
      margin-bottom: 44px;
    }

    &__block span {
      font-weight: 600;
    }

    &__desc {
      margin-top: 20px;
      font-size: 17px;
      line-height: 22px;
    }

    &__more {
      font-size: 17px;
      line-height: 22px;
    }

    &__info {
      padding: 16px 18px;
      font-size: 15px;
      line-height: 20px;
    }
  }
}
</style>
