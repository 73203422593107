<template>
  <div class="home-tools-slider">
    <div class="home-tools-slider__wrapper">
      <perfect-scrollbar>
        <home-tools-slide>
          <template #title>
            {{
              te(`${baseT}item.${activeTool.name}.title_full`)
                  ? t(`${baseT}item.${activeTool.name}.title_full`)
                  : t(`${baseT}item.${activeTool.name}.title`)

            }}
          </template>
          <template #icon>
            <img :src="activeTool.icon" :alt="activeTool.name">
          </template>
          <template #desc>
            <p v-for="number in activeTool.countDesc" :key="number">
              {{ t(`${baseT}item.${activeTool.name}.desc_${number}`) }}
            </p>
          </template>
          <template v-if="activeTool.image" #image>
            <img :src="activeTool.image" :alt="activeTool.name">
          </template>
        </home-tools-slide>
      </perfect-scrollbar>
      <div class="home-tools-slider__close" @click="close">
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="10.5947" cy="10.4614" r="9.5" stroke="currentColor"/>
          <path
              d="M15.1267 13.8635L13.8947 15.0675L10.4787 11.6795L7.09072 15.0675L5.88672 13.8355L9.27472 10.4755L5.91472 7.08747L7.14672 5.88347L10.4787 9.24347L13.8667 5.85547L15.0707 7.08747L11.7107 10.4755L15.1267 13.8635Z"
              fill="currentColor"/>
        </svg>
      </div>
      <div class="home-tools-slider__nav">
        <div class="home-tools-slider__prev" @click="prev">
          <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.10201 4.24418C0.906751 4.04892 0.906751 3.73233 1.10201 3.53707L4.28399 0.355092C4.47926 0.159829 4.79584 0.159829 4.9911 0.355092C5.18636 0.550354 5.18636 0.866936 4.9911 1.0622L2.16267 3.89063L4.9911 6.71905C5.18636 6.91431 5.18636 7.2309 4.9911 7.42616C4.79584 7.62142 4.47926 7.62142 4.28399 7.42616L1.10201 4.24418ZM10.4556 4.39062L1.45557 4.39063L1.45557 3.39063L10.4556 3.39062L10.4556 4.39062Z"
                fill="currentColor"/>
          </svg>
          {{ t(`${baseT}prev`) }}
        </div>
        <div class="home-tools-slider__next" @click="next">
          {{ t(`${baseT}next`) }}
          <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0723 4.24418C10.2676 4.04892 10.2676 3.73233 10.0723 3.53707L6.89032 0.355092C6.69506 0.159829 6.37848 0.159829 6.18322 0.355092C5.98795 0.550354 5.98795 0.866936 6.18322 1.0622L9.01164 3.89063L6.18322 6.71905C5.98795 6.91431 5.98795 7.2309 6.18322 7.42616C6.37848 7.62142 6.69506 7.62142 6.89032 7.42616L10.0723 4.24418ZM0.71875 4.39062L9.71875 4.39063L9.71875 3.39063L0.71875 3.39062L0.71875 4.39062Z"
                fill="currentColor"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import HomeToolsSlide from '@/pages/Home/components/HomeToolsSlide'
import toolsList from '@/helpers/toolsList'
import {computed, defineProps, defineEmits, toRef} from 'vue'
import {useI18n} from 'vue-i18n'
import {PerfectScrollbar} from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const emit = defineEmits(['close', 'change'])

const props = defineProps({active: {type: Number}})
const active = toRef(props, 'active')

const {t, te} = useI18n()
const baseT = 'home.tools.'

const activeTool = computed(() => toolsList[active.value])

const close = () => emit('close')
const change = idx => emit('change', idx)
const prev = () => change(active.value ? active.value - 1 : toolsList.length - 1)
const next = () => change(active.value === toolsList.length - 1 ? 0 : active.value + 1)
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-tools-slider {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 540px;
  padding: 85px 16px 16px;
  z-index: 9999;

  .ps {
    height: 100%;
    padding-right: 25px;
    margin-right: -25px;
  }

  &__wrapper {
    position: relative;
    box-shadow: 0 9px 37px -3px rgba(28, 32, 41, 0.28);
    border-radius: 23px;
    padding: 30px 30px 80px 30px;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    &::after {
      position: absolute;
      inset: 0;
      content: '';
      background: rgba(245, 245, 251, 0.6);
      backdrop-filter: blur(30px);
      z-index: -1;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    transition: $transition-default;

    &:hover {
      cursor: pointer;
      color: $color-danger;
    }
  }

  &__nav {
    position: absolute;
    display: flex;
    bottom: 30px;
    left: 30px;
    align-items: center;
    gap: 24px;
    line-height: 21px;
    user-select: none;

    & > div {
      transition: $transition-default;

      &:hover {
        cursor: pointer;
        color: $color-danger;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 86px 16px 52px 16px;

    .ps {
      padding-right: 13px;
      margin-right: -13px;
    }

    &__nav {
      display: none;
    }

    &__wrapper {
      padding: 25px 15px 25px 20px;
    }
  }
}
</style>
