<template>
  <div class="home-integration">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <header>
            <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="home-integration__desc" v-html="t(`${baseT}desc_1`)"/>
          <a :href="linkDeveloper" class="home-integration__dev d-none d-lg-block" target="_blank">
            <span>{{ t(`${baseT}desc_2`) }}</span>
            <IconLink/>
          </a>
        </div>
        <div class="home-integration__image col-lg-6 offset-lg-1 d-none d-lg-block">
          <img :src="imageIntegration" alt="integration">
        </div>
      </div>
      <div class="home-integration__mobile d-grid d-lg-none">
        <a :href="linkDeveloper" class="home-integration__dev" target="_blank">
          <IconLink/>
          <span>{{ t(`${baseT}desc_2`) }}</span>
        </a>
        <img :src="imageIntegration" alt="integration">
      </div>
    </div>
  </div>
</template>

<script setup>
import imageIntegration from '@/assets/integration.png'
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import IconLink from '@/components/Icon/IconLink'

const {t, locale} = useI18n()
const baseT = 'home.integration.'
const linkDeveloper = locale.value === 'ru'
    ? 'https://erachain.ru/home-developers'
    : 'https://erachain.org/home-developers'
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.home-integration {
  &__desc {
    margin-top: 36px;
    font-size: 24px;
    line-height: 34px;

    a {
      color: $color-danger;
      text-decoration: none;
      transition: .5s;

      &:hover {
        color: darken($color-danger, 20%);
      }
    }
  }

  &__image {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      padding-top: 70%;
      background-image: url("~@/assets/shadow-danger.svg");
      background-size: cover;
      top: -10%;
      right: -30%;
      z-index: -1;
      transform: rotate(-30deg);
      filter: blur(60px);
    }
  }

  &__dev {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 28px;
    text-decoration: none;
    color: inherit;
    font-weight: 600;
    transition: .5s;

    &:hover {
      color: $color-danger;
    }
  }

  &__mobile {
    display: grid;
    align-items: flex-end;
    padding: 12px 18px;
    grid-template-columns: 50% 50%;
    background-color: #E3E1F1;
    border-radius: 20px;
    margin-top: 18px;

    img {
      max-width: 100%;
    }
  }

  @media (max-width: 991px) {
    &__image {
      text-align: center;

      img {
        max-width: 60%;
      }
    }
  }

  @media (max-width: 575px) {
    &__desc {
      margin-top: 20px;
      font-size: 17px;
      line-height: 22px;
    }

    &__dev {
      flex-direction: column;
      align-items: flex-start;
      font-size: 17px;
      line-height: 22px;
    }
  }
}
</style>
