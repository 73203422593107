<template>
  <div class="home-exchanger">
    <div class="container">
      <div class="home-exchanger__wrapper">
        <div class="row gy-4">
          <div class="col-lg-5">
            <div class="home-exchanger__content">
              <div class="home-exchanger__panel">
                <base-tag>{{ t(`${baseT}tag`) }}</base-tag>
                <a class="home-exchanger__link" :href="exchangerLink" target="_blank">
                  <IconLink/>
                </a>
              </div>
              <header class="home-exchanger__header">
                <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
                <div class="home-exchanger__desc">{{ t(`${baseT}desc`) }}</div>
              </header>
              <div class="home-exchanger__info">
                <p>{{ t(`${baseT}desc_1`) }}</p>
                <p>{{ t(`${baseT}desc_2`) }}</p>
                <p>{{ t(`${baseT}desc_3`) }}</p>
              </div>
            </div>
          </div>
          <div class="home-exchanger__image col-lg-6 offset-md-1">
            <img :src="imageExchanger" alt="exchanger">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import imageExchanger from '@/assets/exchanger.png'
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import IconLink from '@/components/Icon/IconLink'
import BaseTag from '@/components/Base/BaseTag'

const {t} = useI18n()
const baseT = 'home.exchanger.'

const exchangerLink = 'https://face2face.cash/'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";
@import "~@/scss/functions.scss";

.home-exchanger {
  .container {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: getWidthPercentShadow(406);
      padding-top: getHeightPercentShadowPrimary(332);
      background-image: url("~@/assets/shadow-primary.svg");
      background-size: cover;
      top: 10%;
      right: 10%;
      filter: blur(90px);
      opacity: .45;
      z-index: -1;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    border: 1px solid $color-danger;
    box-shadow: 0 5px 30px -9px rgba(0, 45, 141, 0.4);
    border-radius: 20px;
    background: rgba(6, 13, 44, 0.5);
    /*

        &:before {
          position: absolute;
          content: '';
          width: getWidthPercentShadow(713);
          padding-top: getHeightPercentShadowDanger(713);
          background-image: url("~@/assets/shadow-danger.svg");
          background-size: cover;
          top: -17%;
          right: -15%;
          z-index: -1;
          filter: blur(90px);
        }*/
  }

  &__panel {
    display: flex;
    justify-content: space-between;
  }

  &__header {
    margin-top: 44px;
    margin-bottom: 31px;
  }

  &__desc {
    font-size: 24px;
    line-height: 34px;
    margin-top: 17px;
    padding-bottom: 33px;
    border-bottom: 1px solid #494D61;
  }

  &__info {
    color: #ADB0BC;
  }

  &__link {
    position: absolute;
    right: 33px;
  }

  &__image {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 50px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__content {
    padding: 28px 0 28px 28px;
    margin-right: -5px;
  }

  @media (max-width: 991px) {
    &__content {
      padding: 0 40px;
    }

    &__image img {
      border-radius: 20px;
    }
  }

  @media (max-width: 767px) {
    &__image {
      display: none;
    }

    &__content {
      margin-top: 40px;
    }

    &__header, &__info {
      padding-right: 0;
    }
  }

  @media (max-width: 575px) {
    &__content {
      margin-top: 16px;
      padding: 0 16px;
    }

    &__header {
      margin-bottom: 16px;
    }

    &__desc {
      font-size: 17px;
      line-height: 22px;
      margin-top: 10px;
      padding-bottom: 16px;
    }

    &__info {
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 16px;

      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
