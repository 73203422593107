<template>
  <div class="home-advantage-item-light">
    <div class="row gy-4">
      <div class="col-lg-5 order-last order-lg-first">
        <div class="home-advantage-item-light__table">
          <div class="home-advantage-item-light__tr">
            <div class="home-advantage-item-light__th"></div>
            <div class="home-advantage-item-light__th">{{ t(`${baseT}fin_size`) }}</div>
            <div class="home-advantage-item-light__th">{{ t(`${baseT}voice_size`) }}</div>
          </div>
          <div
              v-for="{name, finSize, finSizeUrl, voiceSize, voiceSizeUrl} in txs"
              class="home-advantage-item-light__tr"
              :key="name"
          >
            <div class="home-advantage-item-light__td">{{ name }}</div>
            <div class="home-advantage-item-light__td">
              <a class="home-advantage-item-light__link" v-if="finSizeUrl" :href="finSizeUrl" target="_blank">
                <span>{{ finSize }}</span>
                <IconLink/>
              </a>
              <span v-else>{{ finSize }}</span>
            </div>
            <div class="home-advantage-item-light__td">
              <a class="home-advantage-item-light__link" v-if="voiceSizeUrl" :href="voiceSizeUrl" target="_blank">
                <span>{{ voiceSize }}</span>
                <IconLink/>
              </a>
              <span v-else>{{ voiceSize }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <header>
          <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
        </header>
        <div class="home-advantage-item-light__desc">
          <p>{{ t(`${baseT}desc_1`) }}</p>
          <p>{{ t(`${baseT}desc_2`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import IconLink from '@/components/Icon/IconLink'

const {t} = useI18n()
const baseT = 'home.advantage.items.light.'

const txs = [
  {
    name: 'Erachain',
    finSize: '159 byte',
    finSizeUrl: 'https://explorer.erachain.org/index/blockexplorer.html?tx=96705-1&lang=ru',
    voiceSize: '129 byte',
    voiceSizeUrl: 'https://explorer.erachain.org/index/blockexplorer.html?tx=1896546-1&lang=ru',
  },
  {
    name: 'Solana',
    finSize: '220 byte',
    voiceSize: '338 byte',
    voiceSizeUrl: 'https://explorer.solana.com/tx/2oj2LX45Cy264pFTGvtMWAApBEVZLRsKv7UmckmTCDKUtAGRpXQMLmsNg52yPg3WAwgnZnDghiKw5zirPFLNKmLC/inspect',
  },
  {
    name: 'Ethereum',
    finSize: '250 byte',
    voiceSize: '-',
  },
  {
    name: 'Bitcoin',
    finSize: '258 byte',
    voiceSize: '-',
  },
]
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-advantage-item-light {
  &__table {
    border: 2px solid $color-danger;
    border-radius: 29px;
    padding: $row;
  }

  &__tr {
    display: grid;
    grid-template-columns: 1.6fr 1fr 1fr;
    gap: 20px;
    border-bottom: 1px solid $color-danger;

    &:nth-child(2) {
      font-weight: 500;
    }
  }

  &__th {
    font-size: 14px;
    line-height: 18px;
    padding: 6px 0;
  }

  &__td {
    padding: 8px 0;
  }

  &__desc {
    margin-top: $row * 1.5;
    font-size: 24px;
    line-height: 34px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
  }

  @media (max-width: 575px) {
    &__table {
      padding: $row 16px;
    }

    &__tr {
      grid-template-columns: 0.8fr 1fr 1fr;
      gap: 16px;
    }

    &__desc {
      margin-top: 20px;
      font-size: 17px;
      line-height: 22px;
    }
  }
}
</style>
