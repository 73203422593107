<template>
  <div class="home-scope">
    <div class="container">
      <header class="home-scope__header">
        <base-title type="h2" big>{{ t(`${baseT}big_title`) }}</base-title>
      </header>
      <div v-if="isMobile" class="home-scope__mobile">
        <header class="home-scope__subheader">
          <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
        </header>
        <footer class="home-scope__footer">
          {{ t(`${baseT}desc_1`) }}
        </footer>
        <div class="home-scope__grid">
          <div
              v-for="{name, image} in scoped"
              class="home-scope__item"
              :key="name"
          >
            <img :src="image" :alt="name">
            <span>{{ t(`${baseT}scope_${name}`) }}</span>
          </div>
        </div>
      </div>
      <div v-else class="home-scope__grid">
        <header class="home-scope__subheader">
          <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
        </header>
        <div
            v-for="{name, image} in scoped"
            class="home-scope__item"
            :key="name"
        >
          <img :src="image" :alt="name">
          <span>{{ t(`${baseT}scope_${name}`) }}</span>
        </div>
        <footer class="home-scope__footer">
          {{ t(`${baseT}desc_1`) }}
        </footer>
      </div>
      <div class="home-scope__info row gy-4">
        <div class="col-lg-4 offset-lg-2">
          <div class="home-scope__desc">{{ t(`${baseT}desc_2`) }}</div>
        </div>
        <div class="col-lg-5 offset-lg-1 d-flex align-items-center">
          <div class="home-scope__button">
            <base-button @action="goConstructor">{{ t(`${baseT}button`) }}</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'
import BaseButton from '@/components/Base/BaseButton'
import useActionRouter from '@/use/actionRouter'
import {inject} from 'vue'

const {goConstructor} = useActionRouter()

const {t} = useI18n()
const baseT = 'home.scope.'

const isMobile = inject('isMobile')

const scoped = [
  {name: 'finance', image: require('@/assets/scope-finance.png')},
  {name: 'art', image: require('@/assets/scope-art.png')},
  {name: 'game', image: require('@/assets/scope-game.png')},
  {name: 'iot', image: require('@/assets/scope-iot.png')},
  {name: 'insurance', image: require('@/assets/scope-insurance.png')},
  {name: 'resources', image: require('@/assets/scope-resources.png')},
  {name: 'jurisprudence', image: require('@/assets/scope-jurisprudence.png')},
  {name: 'messenger', image: require('@/assets/scope-messenger.png')},
  {name: 'social', image: require('@/assets/scope-social.png')},
  {name: 'delivery', image: require('@/assets/scope-delivery.png')},
  {name: 'medicine', image: require('@/assets/scope-medicine.png')},
  {name: 'ticket', image: require('@/assets/scope-ticket.png')},
  {name: 'realty', image: require('@/assets/scope-realty.png')},
  {name: 'education', image: require('@/assets/scope-education.png')},
  {name: 'other', image: require('@/assets/scope-other.png')},
]
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";
@import "~@/scss/mixins.scss";

.home-scope {
  &__header {
    text-align: center;
    margin-bottom: 124px;

    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
  }

  &__grid {
    display: grid;
    grid-template-areas:
        "header header header header sc1 sc2 sc3 sc4"
        "sc5 sc6 sc7 sc8 sc9 sc10 sc11 null"
        "sc12 sc13 sc14 sc15 footer footer footer footer";
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto auto;
    gap: 45px;
    padding: 0 55px;
  }

  &__subheader {
    grid-area: header;
  }

  &__footer {
    grid-area: footer;
    font-size: 24px;
    line-height: 34px;
    margin-right: -60px;
  }

  &__item {
    text-align: center;

    @for $i from 1 through 16 {
      &:nth-child(#{$i}) {
        grid-area: sc#{$i - 1};
      }
    }

    span {
      margin-top: 10px;
      display: block;
    }
  }

  &__info {
    margin-top: 60px;
  }

  &__desc {
    font-size: 24px;
    line-height: 34px;
  }

  &__button {
    position: absolute;
    padding: 45px;
    border-radius: 120px;
    margin-left: -24px;
    background: linear-gradient(90deg, rgba(24, 175, 242, 1) 0%, rgba(95, 82, 216, 1) 27%, rgba(170, 66, 185, 1) 53%, rgba(244, 77, 97, 1) 75%, rgba(254, 116, 67, 1) 100%), #FFFFFF;

    .base-button {
      box-shadow: none;
    }

    &::before {
      position: absolute;
      content: '';
      inset: 1px;
      border: 17px solid rgba(2, 7, 28, .85);
      border-radius: 120px;
    }

    &::after {
      position: absolute;
      content: '';
      inset: 19px;
      background-color: rgba(2, 7, 28, .85);
      border-radius: 60px;
    }

    & > * {
      position: relative;
      z-index: 1;
    }
  }

  @media (max-width: 1399px) {
    &__bigtitle {
      font-size: 60px;
      line-height: 72px;
    }

    &__grid {
      grid-template-areas:
        "header header header header sc1 sc2"
        "sc3 sc4 sc5 sc6 sc7 sc8"
        "sc9 sc10 sc11 sc12 sc13 sc14"
        "sc15 footer footer footer footer footer";
      grid-template-columns: repeat(6, 1fr);
    }
  }

  @media (max-width: 991px) {
    &__button {
      position: relative;
    }
    &__grid {
      padding: 0;
      grid-template-areas:
        "header header header header"
        "sc1 sc2 sc3 sc4"
        "sc5 sc6 sc7 sc8"
        "sc9 sc10 sc11 sc12"
        "sc13 sc14 sc15 null"
        "footer footer footer footer";
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: 767px) {
    &__bigtitle {
      font-size: 32px;
      line-height: 40px;
    }

    &__grid {
      grid-template-areas:
        "header header"
        "sc1 sc2"
        "sc3 sc4"
        "sc5 sc6"
        "sc7 sc8"
        "sc9 sc10"
        "sc11 sc12"
        "sc13 sc14"
        "sc15 null"
        "footer footer";
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 575px) {
    &__header {
      margin-bottom: 44px;
    }

    &__footer {
      margin-right: 0;
      font-size: 17px;
      line-height: 22px;
      margin-top: 20px;
    }

    &__grid {
      @include horizontal-scroll;
      margin: 49px -16px;
      padding: 0 16px;
      grid-template-areas:
        "sc0 sc1 sc2 sc3 sc4"
        "sc5 sc6 sc7 sc8 sc9"
        "sc10 sc11 sc12 sc13 sc14";
      grid-template-columns: repeat(5, 94px);
      grid-template-rows: auto auto auto;
      gap: 16px;
    }

    &__item {
      font-size: 15px;
      line-height: 18px;
      word-wrap: break-word;
    }

    &__info {
      margin-top: 0;

      & > * {
        margin-top: 0;
      }

      & > div:last-child {
        margin-top: 33px;
        justify-content: center;
      }
    }

    &__desc {
      font-size: 17px;
      line-height: 22px;
    }

    &__button {
      margin-left: 0;
    }
  }
}
</style>
