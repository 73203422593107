<template>
  <div :class="['technology-page', {'technology-page_dark': isDark}]">
    <div class="technology-page__background-dark" ref="background"/>
    <header class="technology-page__header">
      <base-title type="h1">{{ t('technology.title') }}</base-title>
    </header>
    <TechnologyBaseView class="technology-page__base"/>
    <div class="technology-page__dark" ref="dark">
      <HomeSecurityTechnologyView class="technology-page__security-technology" is-full/>
      <TechnologyVerificationView id="tech-1" class="technology-page__verification"/>
      <TechnologyNetworkProtectionView id="tech-2" class="technology-page__network-protection"/>
      <TechnologyAccessControlView id="tech-3" class="technology-page__access-control"/>
      <TechnologyEncryptionView id="tech-5" class="technology-page__encryption"/>
      <TechnologyOnChainView id="tech-6" class="technology-page__on-chain"/>
      <TechnologyDataCollectionView id="tech-7" class="technology-page__data-collection"/>
    </div>
    <TechnologySecurityView class="technology-page__security"/>
  </div>
</template>

<script setup>
import TechnologyBaseView from '@/pages/Technology/views/TechnologyBaseView'
import TechnologySecurityView from '@/pages/Technology/views/TechnologySecurityView'
import HomeSecurityTechnologyView from '@/pages/Home/views/HomeSecurityTechnologyView'
import TechnologyVerificationView from '@/pages/Technology/views/TechnologyVerificationView'
import TechnologyNetworkProtectionView from '@/pages/Technology/views/TechnologyNetworkProtectionView'
import TechnologyAccessControlView from '@/pages/Technology/views/TechnologyAccessControlView'
import TechnologyEncryptionView from '@/pages/Technology/views/TechnologyEncryptionView'
import TechnologyOnChainView from '@/pages/Technology/views/TechnologyOnChainView'
import TechnologyDataCollectionView from '@/pages/Technology/views/TechnologyDataCollectionView'
import {computed, onMounted, onUnmounted, ref} from 'vue'
import {gsap} from '@/myGsap'
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import useCheckout from '@/use/checkout'
import useMeta from '@/use/meta'

const {setTitle, setDescription} = useMeta()
setTitle('О технологии блокчейн-конструктора Eralauncher')
setDescription('Создание блокчейна через конструктор происходит на базе независимого блокчейна Erachain. Децентрализованная сеть работает в режиме mainnet с 2017 года.')

const {t} = useI18n()

const dark = ref()
const background = ref()
const timelineDark = ref()

const {addSetGetField} = useCheckout()
const isDark = computed(addSetGetField({base: 'general', action: 'UPDATE_GENERAL', field: 'isDark'}))

const addAnimationBackground = (trigger, target) => {
  if (trigger.value instanceof HTMLElement) {
    return gsap.timeline({
      scrollTrigger: {
        trigger: trigger.value,
        start: 'top center-=200px',
        end: 'bottom center',
        toggleActions: 'play reverse play reset',
        easy: 'none',
        onEnter() {
          trigger.value.setAttribute('data-active', true)
          isDark.value = [...trigger.value.classList].includes('technology-page__dark')
        },
        onEnterBack() {
          trigger.value.setAttribute('data-active', true)
          isDark.value = [...trigger.value.classList].includes('technology-page__dark')
        },
        onLeave() {
          trigger.value.setAttribute('data-active', false)
          isDark.value = false
        },
        onLeaveBack() {
          trigger.value.setAttribute('data-active', false)
          isDark.value = false
        },
      },
    })
        .timeScale(8)
        .to(target.value, {opacity: 1, ease: 'none'}, 0)
  }
  return null
}

onMounted(() => {
  setTimeout(() => {
    timelineDark.value = addAnimationBackground(dark, background)
  }, 1000)
})

onUnmounted(() => {
  if (timelineDark.value) {
    timelineDark.value.kill()
  }
})
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.technology-page {
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-top: 72px;

  &__background-dark {
    position: fixed;
    inset: 0;
    z-index: -1;
    opacity: 0;
    transition: $transition-default;
    background-color: #02071C;
  }

  &_dark {
    color: #E9E9E9;

    .base-title {
      color: white;
    }
  }

  &__dark {
    position: relative;
    --color-title: black;
    --color-text: #171717;

    .base-title {
      color: var(--color-title);
    }

    &[data-active="true"] {
      --color-title: white;
      --color-text: #E9E9E9;
    }

    @media all and (max-width: 767px) {
      img {
        max-width: 100%;
      }
    }
  }

  &__header {
    text-align: center;
    padding-bottom: 107px;
  }

  &__base {
    padding-top: 50px;
  }

  &__security-technology {
    padding-top: 190px;
  }

  &__verification {
    margin-top: 168px;
  }

  &__network-protection {
    margin-top: 202px;
  }

  &__access-control {
    margin-top: 202px;
  }

  &__encryption {
    margin-top: 202px;
  }

  &__on-chain {
    margin-top: 202px;
  }

  &__data-collection {
    margin-top: 202px;
    padding-bottom: 116px;
  }

  &__security {
    margin-bottom: 136px;
  }

  @media (max-width: 767px) {
    margin-top: 30px;

    &__base {
      padding-top: 0;
    }

    &__security-technology {
      padding-top: 94px;

      .home-security-technology__header {
        margin-bottom: 50px;
      }
    }

    &__verification {
      margin-top: 94px;
    }

    &__network-protection {
      margin-top: 94px;
    }

    &__access-control {
      margin-top: 94px;
    }

    &__encryption {
      margin-top: 94px;
    }

    &__on-chain {
      margin-top: 94px;
    }

    &__data-collection {
      margin-top: 94px;
      padding-bottom: 94px;
    }
  }
}
</style>
