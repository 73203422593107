<template>
  <header class="header" :class="{'header_active': activeMenu, 'header_dark': isDark}">
    <div class="header__background"/>
    <div class="container">
      <div class="header__wrapper">
        <router-link tag="div" :to="{name: 'home', params:{locale}}" class="header__logo">
          <img v-if="isDark" :src="logoWhite" alt="Erachain"/>
          <img v-else :src="logo" alt="Erachain"/>
        </router-link>
        <TheMobileBurger class="header__burger" @click="toggleMenu" :active="activeMenu"/>
        <div class="header__nav">
          <TheNavigation/>
        </div>
        <div class="header__cart">
          <TheCart :is-active-menu="activeMenu"/>
        </div>
        <TheMobileMenu v-if="activeMenu" class="header__mobile-menu"/>
      </div>
    </div>
  </header>
</template>

<script setup>
import logo from '../assets/logo.svg'
import logoWhite from '../assets/logo-white.svg'
import TheNavigation from './TheNavigation.vue'
import TheMobileBurger from '@/components/TheMobileBurger'
import {computed, ref, toRefs, watch} from 'vue'
import {useRoute} from 'vue-router'
import TheCart from '@/components/TheCart'
import {useI18n} from 'vue-i18n'
import TheMobileMenu from '@/components/TheMobileMenu'
import {useStore} from 'vuex'

const {locale} = useI18n()

const activeMenu = ref(false)
const toggleMenu = () => {
  activeMenu.value = !activeMenu.value
}

const store = useStore()

const isDark = computed(() => store.state.general.isDark)

const route = useRoute()
const {name: routeName} = toRefs(route)

watch(routeName, () => {
  activeMenu.value = false
})

</script>

<style lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 13px 0;

  &_dark {
    .mobile-burger:not(.mobile-burger_active) {
      .mobile-burger__line-one::before {
        background-color: white;
      }

      .mobile-burger__line-two {
        &::before, &::after {
          background-color: white;
        }
      }
    }

    @media (min-width: 768px) {
      color: white;
    }
  }

  &__background {
    position: absolute;
    inset: 0;
    backdrop-filter: blur(20px);
  }

  &__logo {
    img {
      max-height: 43px;
      width: auto;
    }
  }

  &__wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 24px;
    align-items: center;
    z-index: 0;
  }

  &__mobile-menu {
    z-index: -1;
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__burger {
    display: none;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 18px 0;

    &__nav {
      display: none;
    }

    &__burger {
      display: block;
    }

    &__logo {
      img {
        max-height: 30px;
      }
    }

    &__cart {
      position: fixed;
      right: 16px;
      bottom: 22px;
    }

    &__wrapper {
      gap: 0;
    }
  }

  .header_active {
    .header__cart {
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
      bottom: 44px;
    }
  }
}
</style>
