<template>
  <home-capability-block class="home-capability-block-blockchain">
    <template #title>{{ t(`${baseT}title`) }}</template>
    <template #info>
      <ul>
        <li>{{ t(`${baseT}li_1`) }}</li>
        <li>{{ t(`${baseT}li_2`) }}</li>
        <li>{{ t(`${baseT}li_3`) }}</li>
        <li>{{ t(`${baseT}li_4`) }}</li>
        <li>{{ t(`${baseT}li_5`) }}</li>
        <li>{{ t(`${baseT}li_6`) }}</li>
      </ul>
    </template>
    <template #image>
      <div class="home-capability-block-blockchain__image">
        <img :src="capabilityBlockchain" alt="capability blockchain">
      </div>
    </template>
  </home-capability-block>
</template>

<script setup>
import capabilityBlockchain from '@/assets/capability-blockchain.png'
import HomeCapabilityBlock from '@/pages/Home/components/HomeCapabilityBlock'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = 'home.capability.blockchain.'
</script>

<style lang="scss">
@import "~@/scss/functions.scss";

.home-capability-block-blockchain {
  &__image {
    position: relative;
    z-index: 0;

    &:after {
      position: absolute;
      content: '';
      width: 60%;
      padding-top: 40%;
      background-image: url("~@/assets/shadow-primary.svg");
      background-size: cover;
      bottom: -7%;
      left: 5%;
      opacity: .7;
      filter: blur(90px);
      z-index: -1;
    }

    &:before {
      position: absolute;
      content: '';
      width: 70%;
      padding-top: 50%;
      background-image: url("~@/assets/shadow-danger.svg");
      background-size: cover;
      top: -10%;
      right: 0;
      z-index: -1;
      transform: rotate(-30deg);
      filter: blur(70px);
    }
  }
}
</style>
