const DEFAULT_VALUES = {
  logo: '',
  logoHorizontal: '',
  colorSidebar: '052335',
  colorSidebarMenu: 'FFFFFF',
  colorButton: '004DFF',
  colorLink: '00B9FF',
}

export default {
  namespaced: true,
  state() {
    return {...DEFAULT_VALUES}
  },
  mutations: {
    UPDATE_STYLE(state, {field, value}) {
      state[field] = value
    },
  },
  actions: {
    async reset({commit}) {
      Object.entries(DEFAULT_VALUES).forEach(([field, value]) => {
        commit('UPDATE_STYLE', {field, value})
      })
    },
    UPDATE_STYLE({commit}, {field, value}) {
      commit('UPDATE_STYLE', {field, value})
    },
  },
}
