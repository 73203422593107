import {
  generateAddressesBySeed,
  generateSeed,
  getAddressBySeedAndNumber,
} from '@/services/erachain'

const DEFAULT_COUNT_ADDRESSES = 20
const DEFAULT_DESKTOP = true
const DEFAULT_WEB = false
const DEFAULT_MOBILE = false
const DEFAULT_INSTALLATION = 'self'
const DEFAULT_MACHINE = null
const DEFAULT_RENT = 'self'
const DEFAULT_SLA = 'free'
const DEFAULT_SLA_PERIOD = 6

const getCorrectSum = sum => {
  return Math.floor(sum * Math.pow(10, 8)) / Math.pow(10, 8)
}

export default {
  namespaced: true,
  state() {
    return {
      name: '',
      seed: '',
      addresses: [],
      status: false,
      desktop: DEFAULT_DESKTOP,
      web: DEFAULT_WEB,
      mobile: DEFAULT_MOBILE,
      installation: DEFAULT_INSTALLATION,
      machine: DEFAULT_MACHINE,
      rent: DEFAULT_RENT,
      sla: DEFAULT_SLA,
      slaPeriod: DEFAULT_SLA_PERIOD,
      isDark: false,
    }
  },
  getters: {
    sumInvestToken(state) {
      return getCorrectSum(state.addresses.reduce((acc, curr) => acc + curr.investCount, 0))
    },
    sumFuelToken(state) {
      return getCorrectSum(state.addresses.reduce((acc, curr) => acc + curr.fuelCount, 0))
    },
  },
  mutations: {
    setName: (state, name) => (state.name = name),
    setSeed: (state, seed) => (state.seed = seed),
    setAddresses: (state, addresses) => state.addresses = addresses,
    setConfigStatus: (state, status) => state.status = status,
    UPDATE_GENERAL(state, {field, value}) {
      state[field] = value
    },
  },
  actions: {
    async init({dispatch}) {
      await dispatch('generateSeed')
      await dispatch('initAddresses')
    },
    async reset({dispatch}) {
      await dispatch('generateSeed')
      await dispatch('initAddresses')
      await dispatch('updateName', '')
      await dispatch('updateDesktop', DEFAULT_DESKTOP)
      await dispatch('updateMobile', DEFAULT_MOBILE)
      await dispatch('updateWeb', DEFAULT_WEB)
      await dispatch('updateInstallation', DEFAULT_INSTALLATION)
      await dispatch('updateMachine', DEFAULT_MACHINE)
      await dispatch('updateRent', DEFAULT_RENT)
      await dispatch('updateSLA', DEFAULT_SLA)
      await dispatch('resetConfigStatus')
      await dispatch('updateWeb', false)
    },
    UPDATE_GENERAL({commit}, {field, value}) {
      commit('UPDATE_GENERAL', {field, value})
    },
    updateName: ({commit}, name) => commit('setName', name),
    async generateSeed({commit}) {
      return commit('setSeed', await generateSeed())
    },
    async initAddresses({commit, state}) {
      commit('setAddresses', await generateAddressesBySeed(
          state.seed,
          DEFAULT_COUNT_ADDRESSES,
      ))
    },
    async addAddress({commit, state}) {
      const addresses = JSON.parse(JSON.stringify(state.addresses))
      const number = addresses.length
      addresses.push(await getAddressBySeedAndNumber(state.seed, number))
      commit('setAddresses', addresses)
    },
    removeAddress({state, commit}) {
      const addresses = JSON.parse(JSON.stringify(state.addresses))
      addresses.pop()
      commit('setAddresses', addresses)
    },
    async calculateTokens({state, commit, rootState}) {
      const addresses = JSON.parse(JSON.stringify(state.addresses))
      if (rootState.distribution.autoDistribution) {
        const addressesCount = state.addresses.length
        const {investTokenCount, fuelTokenCount} = rootState.emission
        const investCountItem = Math.floor(
            investTokenCount / addressesCount * 100,
        ) / 100
        const fuelCountItem = Math.floor(
            fuelTokenCount / addressesCount * 100,
        ) / 100
        commit('setAddresses', addresses.map(address => {
          address.investCount = investCountItem
          address.fuelCount = fuelCountItem
          return address
        }))
      }
    },
    updateConfigStatus({commit}, status) {
      commit('setConfigStatus', status)
    },
    resetConfigStatus({state, commit}) {
      if (state.status) {
        commit('setConfigStatus', false)
      }
    },
    updateDesktop({state, dispatch}, status) {
      state.desktop = !!status
      dispatch('cart/updateCart', null, {root: true})
    },
    updateMobile({state, dispatch}, status) {
      state.mobile = !!status
      dispatch('cart/updateCart', null, {root: true})
    },
    updateWeb({state, dispatch}, status) {
      state.web = !!status
      dispatch('cart/updateCart', null, {root: true})
    },
    updateInstallation({state, dispatch}, type) {
      state.installation = type
      dispatch('cart/updateCart', null, {root: true})
    },
    updateMachine({state, dispatch}, count) {
      state.machine = count
      dispatch('cart/updateCart', null, {root: true})
    },
    updateRent({state, dispatch}, type) {
      state.rent = type
      dispatch('cart/updateCart', null, {root: true})
    },
    updateSLA({state, dispatch}, sla) {
      state.sla = sla
      dispatch('cart/updateCart', null, {root: true})
    },
    updateSLAPeriod({state, dispatch}, period) {
      state.slaPeriod = period
      dispatch('cart/updateCart', null, {root: true})
    },
  },
}
