<template>
  <div class="contacts-page">
    <div class="container">
      <header class="contacts-page__header text-center">
        <base-title>{{ t(`${baseT}title`) }}</base-title>
      </header>
      <div class="contacts-page__wrapper">
        <ContactsFormView/>
        <ContactsInfoView class="contacts-page__info"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import ContactsFormView from '@/pages/Contacts/views/ContactsFormView'
import ContactsInfoView from '@/pages/Contacts/views/ContactsInfoView'
import useMeta from '@/use/meta'

const {setTitle, setDescription} = useMeta()
setTitle('Контакты | Eralauncher')
setDescription('Оставьте заявку не бесплатную онлайн консультацию или свяжитесь с нашими сотрудниками через telegram или whatsapp.')

const {t} = useI18n()
const baseT = 'contacts.'

</script>

<style lang="scss" scoped>
.contacts-page {
  margin-top: 75px;

  &__wrapper {
    margin-top: 108px;
    margin-bottom: 180px;
  }

  &__info {
    margin-top: 134px;
  }
}

@media (max-width: 575px) {
  .contacts-page {
    margin-top: 0;

    &__wrapper {
      margin-top: 58px;
      margin-bottom: 160px;
    }

    &__info {
      margin-top: 84px;
    }
  }
}
</style>
