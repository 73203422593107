<template>
  <div
      class="base-color-picker"
      :class="{'base-color-picker_white': isWhite}"
      :style="{'--color': '#' + currentColor}"
      tabindex="1"
      @click="showPicker"
      @focusout="hidePicker"
  >
    <div class="base-color-picker__example"/>
    <div class="base-color-picker__type">HEX</div>
    <div
        class="base-color-picker__value"
        contenteditable="true"
        @paste="onPasteColor"
        @keydown="onKeydownColor"
        @input="onInputColor"
    >{{ currentColor }}
    </div>
    <div v-if="isPickerShow" class="base-color-picker__select">
      <ColorPicker
          theme="light"
          :color="`#${currentColor}`"
          :sucker-canvas="null"
          :sucker-area="[]"
          :colors-default="[]"
          @change-color="changeColor"
      />
    </div>
  </div>
</template>

<script>
import {ref, watch, inject, computed} from 'vue'
import {ColorPicker} from 'vue-color-kit'

export default {
  name: 'BaseColorPicker',
  components: {ColorPicker},
  props: {
    color: {
      type: String,
      default: 'CCCCCC',
    },
  },
  emits: ['update:color'],
  setup(props, {emit}) {
    const openForm = inject('openForm')
    const currentColor = ref(props.color)
    const isPickerShow = ref(!!openForm)
    const changeColor = col => currentColor.value = col.hex.substr(1)

    const regexHex = /[A-F0-9]/i
    const onPasteColor = event => {
      const addText = event.clipboardData.getData('text')
      const fullLength = event.target.innerText.length + addText.length
      if (fullLength > 6 || !regexHex.test(addText)) {
        event.preventDefault()
      }
    }
    const onKeydownColor = event => {
      const {extentOffset, baseOffset} = window.getSelection()
      const isCtrlC = event.key === 'c' && event.ctrlKey
      const isCtrlV = event.key === 'v' && event.ctrlKey
      const isBackspace = event.key === 'Backspace'
      const selectedCount = extentOffset - baseOffset
      const isMaxLength = event.target.innerText.length >= 6
      const isNotValid = !regexHex.test(event.key)

      if (isCtrlC || isBackspace || isCtrlV) return
      if ((isMaxLength && selectedCount === 0) || isNotValid) {
        event.preventDefault()
      }
    }
    const onInputColor = event => {
      if (event.target.innerText.length === 6) {
        currentColor.value = event.target.innerText
      }
    }

    const showPicker = () => isPickerShow.value = true
    const hidePicker = () => isPickerShow.value = !!openForm
    const isWhite = computed(() => currentColor.value === 'FFFFFF')

    watch(currentColor, value => {
      emit('update:color', value)
    })

    return {
      currentColor: currentColor,
      isPickerShow,
      onPasteColor,
      onKeydownColor,
      onInputColor,
      changeColor,
      showPicker,
      hidePicker,
      isWhite,
    }
  },
}
</script>

<style lang="scss">
@import "../../scss/variables";

.base-color-picker {
  position: relative;
  display: grid;
  grid-template-columns: 44px auto 1fr;
  height: 44px;
  gap: 12px;
  padding-right: 12px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--color);
  outline: none;

  &_white {
    border: 1px solid black;

    .base-color-picker__example {
      border-right-color: black;
    }
  }

  &__example {
    height: 100%;
    border-radius: 4px 0 0 4px;
    background-color: var(--color);
    cursor: pointer;
    border-right: 1px solid transparent;
  }

  &__type {
    color: $border-color;
  }

  &__value {
    font-feature-settings: 'pnum' on, 'lnum' on;
    outline: none;
  }

  &__select {
    position: absolute;
    bottom: 60px;

    .hu-color-picker {
      .color-type, .color-show, .color-alpha {
        display: none
      }
    }
  }
}
</style>
