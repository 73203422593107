<template>
  <div class="home-custom">
    <div class="container">
      <header class="home-custom__header text-center">
        <base-title class="home-custom__title" type="h2" big>{{ t(`${baseT}title`) }}</base-title>
      </header>
      <div class="home-custom__info">
        <div>
          <div class="home-custom__desc">{{ t(`${baseT}desc`) }}</div>
          <div class="home-custom__sub-desc">{{ t(`${baseT}sub_desc`) }}</div>
        </div>
        <div class="home-custom__image">
          <img :src="imageCustom" alt="custom">
        </div>
      </div>
      <div class="home-custom__wrapper">
        <div class="row">
          <div class="home-custom__image col-md-5">
            <img :src="imageCustom" alt="custom">
          </div>
          <div class="col-md-7">
            <section class="home-custom__block">
              <header class="home-custom__block-header">
                <div>
                  <base-title type="h3">{{ t(`${baseT}block.title`) }}</base-title>
                  <div class="home-custom__block-desc">{{ t(`${baseT}block.desc`) }}</div>
                </div>
                <img :src="iconCustom" alt="custom">
              </header>
              <div class="home-custom__block-list">
                <div
                    v-for="number in 9"
                    class="home-custom__block-item"
                    :key="number"
                >{{ t(`${baseT}block.li_${number}`) }}
                </div>
              </div>
              <div class="home-custom__block-buttons">
                <base-button @action="goContacts">{{ t(`${baseT}block.button`) }}</base-button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import imageCustom from '@/assets/custom.png'
import iconCustom from '@/assets/icon-custom.svg'
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'
import BaseButton from '@/components/Base/BaseButton'
import useActionRouter from '@/use/actionRouter'

const {t} = useI18n()
const baseT = 'home.custom.'

const {goContacts} = useActionRouter()
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";
@import "~@/scss/functions.scss";

.home-custom {
  &__info {
    width: 66.666666%;
    margin: 0 auto 58px;
    text-align: center;

    .home-custom__image {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 0;

    .home-custom__image {
      text-align: center;

      @media (max-width: 767px) {
        img {
          max-height: 300px;
        }
      }
    }

    &:after, &:before {
      position: absolute;
      content: '';
      z-index: -1;
      background-size: cover;
      filter: blur(90px);
    }

    &:before {
      width: getWidthPercentShadow(580);
      padding-top: getHeightPercentShadowDanger(580);
      top: 18%;
      left: 20%;
      background-image: url("~@/assets/shadow-danger.svg");
    }

    &:after {
      width: getWidthPercentShadow(700);
      padding-top: getHeightPercentShadowPrimary(700);
      top: -20%;
      left: -13%;
      background-image: url("~@/assets/shadow-primary.svg");
      opacity: 0.6;
    }
  }

  &__title {
    margin-bottom: 69px;
  }

  &__desc {
    font-weight: 400;
    font-size: 42px;
    line-height: 49px;
    margin-bottom: 23px;
  }

  &__sub-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #69749B;
  }

  &__block {
    margin-top: $row;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 9px 41px -9px rgba(106, 113, 128, 0.49);
    border-radius: 30px;
    padding: 42px 50px 42px 56px;

    @media (max-width: 767px) {
      padding: 42px 30px 42px 36px;
    }

    &-header {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 32px;
      font-weight: 500;

      .base-title {
        font-size: 24px;
        line-height: 26px;
        font-weight: 500;
      }
    }


    &-list {
      width: 100%;
      margin-top: 34px;
      margin-bottom: 30px;
    }

    &-item {
      padding: 10px 0;
      border-bottom: 1px solid #171717;

      &:first-child {
        border-top: 1px solid #171717;
      }
    }

    &-buttons {
      text-align: right;
    }
  }

  @media (max-width: 767px) {
    &__title {
      margin-bottom: 30px;
    }

    &__desc {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 23px;
    }
  }

  @media (max-width: 575px) {
    &__wrapper {
      .home-custom__image {
        display: none;
      }
    }

    &__info {
      display: grid;
      max-height: 300px;
      overflow: hidden;
      width: auto;
      text-align: left;
      margin: 0 -16px 0;
      padding: 0 16px;
      grid-template-columns: 1fr 165px;


      .home-custom__image {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
          max-width: 160%;
        }
      }
    }

    &__desc {
      margin-top: 32px;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 12px;
    }

    &__sub-desc {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.01em;
    }

    &__block {
      margin-top: 0;
      border-radius: 20px;
      padding: 25px 16px 39px;
      font-size: 17px;
      line-height: 22px;

      &-desc {
        margin-top: 7px;
      }

      &-header {
        gap: 0;
        white-space: nowrap;

        .base-title {
          font-size: 22px;
          line-height: 26px;
          font-weight: 600;
          white-space: normal;
        }

        img {
          position: relative;
          left: -10px;
          top: -10px;
        }
      }


      &-list {
        margin-top: 22px;
      }

      &-buttons {
        text-align: center;
      }
    }
  }
}
</style>
