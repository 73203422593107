<template>
  <div class="home-capability-apps">
    <div class="home-capability-apps__item" data-name="мобильное">
      <img
          :src="imageApplicationMobileMini"
          alt="mobile"
          @click="toggleModal(true, 0)"
      >
    </div>
    <div class="home-capability-apps__item" data-name="веб">
      <img
          :src="imageApplicationWebMini"
          alt="web"
          @click="toggleModal(true, 1)"
      >
    </div>
    <div class="home-capability-apps__item" data-name="десктоп">
      <img
          :src="imageApplicationDesktopMini"
          alt="desktop"
          @click="toggleModal(true, 2)"
      >
    </div>
    <div v-if="openModal" class="home-capability-apps__modal">
      <div class="home-capability-apps__close" @click="toggleModal(false)" v-html="'&times;'"/>
      <swiper :modules="modules" navigation :initialSlide="initialSlide" :space-between="200">
        <swiper-slide
            v-for="{name, image, imageEn} in slides"
            class="home-capability-apps__slide"
            :key="name"
        >
          <div class="home-capability-apps__slide-img">
            <img :src="locale === 'en' && imageEn ? imageEn : image" :alt="`slide ${name}`">
          </div>
          <div class="home-capability-apps__slide-title">
            {{ t(`${baseT}${name}_title`) }}
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import imageApplicationMobileMini from '@/assets/capability-app-mobile-mini.png'
import imageApplicationWebMini from '@/assets/capability-app-tablet-mini.png'
import imageApplicationDesktopMini from '@/assets/capability-app-desktop-mini.png'
import capabilityAppMobile from '@/assets/capability-app-mobile.png'
import capabilityAppMobileEn from '@/assets/capability-app-mobile-en.png'
import capabilityAppTablet from '@/assets/capability-app-tablet.png'
import capabilityAppTabletEn from '@/assets/capability-app-tablet-en.png'
import capabilityAppDesktop from '@/assets/capability-app-desktop.png'
import {ref} from 'vue'
import {Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue.js'
import 'swiper/swiper-bundle.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import {useI18n} from 'vue-i18n'

const {t, locale} = useI18n()
const baseT = 'home.capability.applications.'

const modules = [Navigation]
const slides = [
  {name: 'mobile', image: capabilityAppMobile, imageEn: capabilityAppMobileEn},
  {name: 'web', image: capabilityAppTablet, imageEn: capabilityAppTabletEn},
  {name: 'desktop', image: capabilityAppDesktop},
]

const openModal = ref(false)
const initialSlide = ref(0)

const toggleModal = (status, slideIdx = 0) => {
  if (openModal.value !== status) {
    initialSlide.value = slideIdx
    openModal.value = status
  }
}
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.home-capability-apps {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__close {
    position: absolute;
    font-size: 70px;
    color: white;
    right: 5vw;
    top: 100px;
    cursor: pointer;
    z-index: 9;

    &:hover {
      color: $color-danger;
    }
  }

  &__item {
    position: relative;
    text-align: center;

    &::before {
      position: absolute;
      content: attr(data-name);
      transform: rotate(-90deg);
      transform-origin: left bottom;
      bottom: 0;
      margin-left: -5px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    img {
      display: inline-block;
      border: 2px solid #E2E2E8;
      box-shadow: 0 10px 15px -4px rgba(105, 116, 155, 0.26);
      border-radius: 2.77869px;

      &:hover {
        cursor: zoom-in;
      }
    }
  }

  &__modal {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-self: center;
    background: rgba(0, 0, 0, 0.4);

    .swiper {
      max-width: 90vw;
      max-height: 80vh;
      padding: 0 80px;

      &-button-next, &-button-prev {
        top: 50%;
        width: 54px;
        height: 54px;
        border-radius: 27px;
        border: 1px solid white;
        color: white;

        &:hover:not(.swiper-button-disabled) {
          background-color: transparent;
          border: 1px solid $color-danger;
          color: $color-danger;
        }

        &:after {
          font-size: 30px;
        }
      }

      &-button-disabled {
        opacity: 1 !important;
        border: 1px solid $border-color;
        color: $border-color;
      }

      &-button-prev {
        left: 0;
      }

      &-button-next {
        right: 0;
      }
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;

    &-img {
      position: relative;

      img {
        max-height: 60vh !important;
        height: auto;
        width: auto;
        border-radius: 24px;
      }

      &:after {
        position: absolute;
        content: '';
        inset: -4px;
        border: 5px solid #E2E2E8;
        border-radius: 26px;
      }
    }

    &-title {
      margin-top: 46px;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: white;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
