<template>
  <div class="home-security-technology" :class="{'home-security-technology_is-full': isFull}">
    <div class="container">
      <header class="home-security-technology__header">
        <base-title type="h2" big>{{ t(`${baseT}title`) }}</base-title>
      </header>
      <div class="home-security-technology__content row">
        <div class="home-security-technology__image col-md-5">
          <img :src="imageSecurityTechnology" alt="security technology">
        </div>
        <div class="col-md-6 offset-md-1">
          <base-title type="h2">{{ t(`${baseT}list_title`) }}</base-title>
          <ul class="home-security-technology__list">
            <li
                v-for="number in 7" :key="number"
                v-scroll-to="isFull ? `#tech-${number}` : undefined"
            >
              {{ t(`${baseT}li_${number}`) }}
            </li>
          </ul>
          <base-button v-if="!isFull" @action="goTechnologyPage" color="light">
            {{ t(`${baseT}button`) }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import imageSecurityTechnology from '@/assets/security-technology.png'
import {useI18n} from 'vue-i18n'
import {defineProps} from 'vue'
import BaseTitle from '@/components/Base/BaseTitle'
import BaseButton from '@/components/Base/BaseButton'
import {useRouter} from 'vue-router'

defineProps({
  isFull: {
    type: Boolean,
    default: false,
  },
})

const {t} = useI18n()
const baseT = 'home.security_technology.'

const router = useRouter()
const goTechnologyPage = () => {
  router.push({name: 'technology'})
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-security-technology {
  &__header {
    text-align: center;
    margin-bottom: 96px;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  &__image {
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__list {
    display: grid;
    gap: 10px;
    margin-top: 34px;
    margin-bottom: 40px;

    li {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &_is-full {
    .home-security-technology__list {
      li {
        cursor: pointer;
        transition: $transition-default;

        &:hover {
          color: $color-danger;
        }
      }
    }

    .home-security-technology__content {
      padding-top: 80px;
      padding-bottom: 80px;
      border-top: 1px solid #28303D;
      border-bottom: 1px solid #28303D;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
    }

    &__content {
      padding-top: 56px;
      padding-bottom: 56px;
    }
  }

  @media (max-width: 575px) {
    &__content {
      padding-top: 24px;
      padding-bottom: 38px;
    }

    &__image {
      margin-bottom: 20px;

      img {
        max-width: 55%;
      }
    }

    &__list {
      margin-top: 20px;
      margin-bottom: 30px;
      padding-left: 20px;

      li {
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}
</style>
