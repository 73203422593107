export default function() {
  const setTitle = title => {
    document.title = title
  }
  const setDescription = description => {
    document.querySelector('meta[name="description"]').setAttribute('content', description)
  }

  return {
    setTitle,
    setDescription,
  }
}
