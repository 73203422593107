<template>
  <div class="home-tools">
    <div class="container">
      <header class="home-tools__header">
        <base-title type="h2" big>
          {{ t(`${baseT}title`) }}
        </base-title>
      </header>
      <div class="home-tools__info">
        <div class="row gy-4">
          <div class="col-md-5">
            <base-title class="home-tools__title" type="h3">
              {{ t(`${baseT}desc_1`) }}
            </base-title>
          </div>
          <div class="col-md-6 offset-md-1">
            <div class="home-tools__desc">
              {{ t(`${baseT}desc_2`) }}
            </div>
          </div>
        </div>
      </div>
      <div class="home-tools__list">
        <div
            v-for="({name, icon}, idx) in toolsList"
            class="home-tools__item"
            :class="{'home-tools__item_active': activeToolsInfo === idx && toolsSliderShow}"
            @click="setActiveToolsInfo(idx)"
            :key="name"
        >
          <div class="home-tools__icon">
            <img :src="icon" :alt="name">
          </div>
          <span>{{ t(`${baseT}item.${name}.title`) }}</span>
        </div>
      </div>
      <transition name="slide-fade">
        <HomeToolsSlider
            v-if="toolsSliderShow && activeToolsInfo >= 0"
            :active="activeToolsInfo"
            @close="closeToolsSlider"
            @change="setActiveToolsInfo"
        />
      </transition>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import HomeToolsSlider from '@/pages/Home/components/HomeToolsSlider'
import toolsList from '@/helpers/toolsList'
import {inject, ref, watch} from 'vue'

const {t} = useI18n()
const baseT = 'home.tools.'

const toolsSliderShow = ref(false)
const activeToolsInfo = ref(-1)

const closeToolsSlider = () => {
  toolsSliderShow.value = false
}

const setActiveToolsInfo = idx => {
  activeToolsInfo.value = idx
  if (!toolsSliderShow.value) toolsSliderShow.value = true
}

const isMobile = inject('isMobile')

watch(toolsSliderShow, value => {
  if (isMobile.value) {
    if (value) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }
})
</script>

<style lang="scss">
@import "~@/scss/variables.scss";
@import "~@/scss/mixins.scss";

.home-tools {
  &__header {
    text-align: center;
    margin-bottom: 124px;

    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
  }

  &__desc {
    font-size: 24px;
    line-height: 34px;
  }

  &__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 56px;
    margin-top: $row * 4;

    &:after {
      position: absolute;
      content: '';
      width: 40%;
      padding-top: 30%;
      background-image: url("~@/assets/shadow-primary.svg");
      background-size: cover;
      top: -10%;
      left: -28%;
      opacity: .6;
      filter: blur(90px);
      z-index: -1;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
    cursor: pointer;

    &_active {
      .home-tools__icon {
        box-shadow: inset 0 0 0 2px $color-primary;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    background: white;
    box-shadow: 0 4.60703px 30.9329px -5.26518px rgba(45, 74, 136, 0.16);
    border-radius: 33.5655px;

    &:hover {
      box-shadow: inset 0 0 0 2px $color-primary;
    }
  }

  .slide-fade-enter-active {
    opacity: 1;
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transition: 1s;
    transform: translateX(225px);
    opacity: 0;
  }
}

@media (max-width: 1199px) {
  .home-tools__list {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 991px) {
  .home-tools__list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 767px) {
  .home-tools__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 575px) {
  .home-tools {
    &__list {
      @include horizontal-scroll;
      margin: 0 -16px;
      padding: 43px 16px 0;
      gap: 16px;
      grid-template-columns: repeat(6, 94px);

      &:after {
        content: none;
      }
    }

    &__header {
      margin-bottom: 44px;
    }

    &__desc {
      font-size: 17px;
      line-height: 22px;
    }

    &__item {
      font-size: 15px;
      line-height: 18px;
    }
  }
}
</style>
