<template>
  <div class="faq-page">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <header class="faq-page__header text-center">
            <base-title>{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="faq-page__wrapper">
            <div class="faq-page__label">{{ t(`${baseT}category_label`) }}</div>
            <div class="faq-page__categories">
              <div
                  v-for="category in categories"
                  class="faq-page__category"
                  :class="{'faq-page__category_active': activeCategory === category}"
                  v-html="t(`${baseT}category_${category}`)"
                  :key="category"
                  @click="toggleCategory(category)"
              />
            </div>
            <div class="faq-page__list">
              <f-a-q-item v-for="{key} in actualQuestions" :key="key">
                <template #question>{{ t(`${baseT}question_${key}`) }}</template>
                <template #answer>{{ t(`${baseT}answer_${key}`) }}</template>
              </f-a-q-item>
            </div>
            <FAQForm class="faq-page__form"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import {computed, ref} from 'vue'
import FAQItem from '@/pages/FAQ/components/FAQItem'
import FAQForm from '@/pages/FAQ/components/FAQForm'
import useMeta from '@/use/meta'

const {setTitle, setDescription} = useMeta()
setTitle('Вопрос-Ответ | Eralauncher')
setDescription('Ответы на популярные вопросы: Как создай свой блокчейн? Возможности блокчейна? Как создать свою криптовалюту?')

const {t} = useI18n()
const baseT = 'faq.'

const categories = ['technology', 'capability', 'start', 'pay']
const activeCategory = ref('technology')

const toggleCategory = category => {
  activeCategory.value = category
}

const questions = [
  {key: 1, category: 'capability'},
  {key: 2, category: 'start'},
  {key: 3, category: 'start'},
  {key: 4, category: 'start'},
  {key: 5, category: 'start'},
  {key: 6, category: 'start'},
  {key: 7, category: 'start'},
  {key: 8, category: 'capability'},
  {key: 9, category: 'capability'},
  {key: 10, category: 'capability'},
  {key: 11, category: 'technology'},
  //{key: 12, category: 'technology'},
  {key: 13, category: 'start'},
  {key: 14, category: 'capability'},
  {key: 15, category: 'capability'},
  {key: 16, category: 'capability'},
  {key: 17, category: 'capability'},
  {key: 18, category: 'technology'},
  {key: 19, category: 'technology'},
  {key: 20, category: 'technology'},
  {key: 21, category: 'start'},
  {key: 22, category: 'pay'},
  {key: 23, category: 'pay'},
  {key: 24, category: 'pay'},
]

const actualQuestions = computed(() => {
  return questions.filter(({category}) => activeCategory.value === category)
})
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.faq-page {
  margin-top: 75px;

  &__wrapper {
    margin-top: 84px;
    margin-bottom: 141px;
  }

  &__categories {
    display: flex;
    gap: 24px;
    margin-top: 16px;
    margin-bottom: 65px;
  }

  &__category {
    display: flex;
    align-items: center;
    background-color: #F5F5FB;
    border-radius: 17px;
    padding: 9px 20px;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    transition: $transition-default;

    &_active {
      background-color: $color-primary;
      color: white;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px $color-primary;
    }
  }

  &__form {
    margin-top: 112px;
  }
}

@media all and (max-width: 991px) {
  .faq-page {
    &__categories {
      flex-wrap: wrap;
    }
  }
}
</style>
