<template>
  <div class="home-promo">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <base-title class="home-promo__title">{{ t('home.promo.title') }}</base-title>
          <div class="home-promo__description">{{ t('home.promo.description') }}</div>
          <base-button @action="goConstructor">{{ t('home.promo.button') }}</base-button>
        </div>
        <div class="home-promo__image d-none d-lg-block col-lg-6 order-first order-lg-last">
          <img :src="promoImage" alt="Promo">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import promoImage from '@/assets/promo-image.jpg'
import BaseButton from '@/components/Base/BaseButton'
import BaseTitle from '@/components/Base/BaseTitle'
import useActionRouter from '@/use/actionRouter'

const {t} = useI18n()
const {goConstructor} = useActionRouter()
</script>

<style lang="scss" scoped>
.home-promo {
  &__title {
    padding-top: 52px;
  }

  &__description {
    margin: 48px 0;
    font-size: 24px;
    line-height: 32px;
    padding-right: 70px;
  }

  @media (max-width: 991px) {
    &__image img {
      max-width: 100%;
      height: auto;
    }
  }

  @media (max-width: 575px) {
    &__title {
      padding-top: 0;
    }

    &__description {
      margin: 16px 0 24px;
      font-size: 20px;
      line-height: 24px;
      padding-right: 0;
    }
  }
}
</style>
