export default [
  {
    name: 'digital-id',
    icon: require('@/assets/tools-digital-id.png'),
    image: require('@/assets/tools-digital-id-image.png'),
    countDesc: 1,
  },
  {
    name: 'tokenization',
    icon: require('@/assets/tools-tokenization.png'),
    image: require('@/assets/tools-tokenization-image.png'),
    countDesc: 1,
  },
  {
    name: 'dex',
    icon: require('@/assets/tools-dex.png'),
    image: require('@/assets/tools-dex-image.png'),
    countDesc: 1,
  },
  {
    name: 'docs',
    icon: require('@/assets/tools-docs.png'),
    image: require('@/assets/tools-docs-image.png'),
    countDesc: 2,
  },
  {
    name: 'dao',
    icon: require('@/assets/tools-dao.png'),
    image: require('@/assets/tools-dao-image.png'),
    countDesc: 1,
  },
  {
    name: 'staking',
    icon: require('@/assets/tools-staking.png'),
    image: require('@/assets/tools-staking-image.png'),
    countDesc: 1,
  },
  {
    name: 'airdrop',
    icon: require('@/assets/tools-airdrop.png'),
    image: require('@/assets/tools-airdrop-image.png'),
    countDesc: 2,
  },
  {
    name: 'ido-ico',
    icon: require('@/assets/tools-ido-ico.png'),
    image: require('@/assets/tools-ido-ico-image.png'),
    countDesc: 2,
  },
  {
    name: 'mail',
    icon: require('@/assets/tools-mail.png'),
    image: require('@/assets/tools-mail-image.png'),
    countDesc: 1,
  },
  {
    name: 'chat',
    icon: require('@/assets/tools-chat.png'),
    image: require('@/assets/tools-chat-image.png'),
    countDesc: 1,
  },
  {
    name: 'digital-print',
    icon: require('@/assets/tools-digital-print.png'),
    image: require('@/assets/tools-digital-print-image.png'),
    countDesc: 3,
  },
  {
    name: 'voting',
    icon: require('@/assets/tools-voting.png'),
    countDesc: 2,
  },
  {
    name: 'status',
    icon: require('@/assets/tools-status.png'),
    countDesc: 2,
  },
  {
    name: 'nft',
    icon: require('@/assets/tools-nft.png'),
    image: require('@/assets/tools-nft-image.png'),
    countDesc: 2,
  },
  {
    name: 'referral-program',
    icon: require('@/assets/tools-referral-program.png'),
    image: require('@/assets/tools-referral-program-image.png'),
    countDesc: 2,
  },
  {
    name: 'bonuses',
    icon: require('@/assets/tools-bonuses.png'),
    countDesc: 2,
  },
]
