<template>
  <home-capability-block class="home-capability-block-branding">
    <template #title>{{ t(`${baseT}title`) }}</template>
    <template #info>
      {{ t(`${baseT}desc`) }}
    </template>
    <template #image>
      <div class="home-capability-block-branding__image">
          <img :src="capabilityBranding" alt="capability blockchain">
      </div>
    </template>
  </home-capability-block>
</template>

<script setup>
import capabilityBranding from '@/assets/capability-branding.png'
import HomeCapabilityBlock from '@/pages/Home/components/HomeCapabilityBlock'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = 'home.capability.branding.'
</script>

<style lang="scss">
.home-capability-block-branding {
  padding-bottom: 11px;

  &__image {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 60%;
      padding-top: 40%;
      background-image: url("~@/assets/shadow-primary.svg");
      background-size: cover;
      bottom: -7%;
      left: 5%;
      opacity: .7;
      filter: blur(90px);
      z-index: -1;
    }

    &:before {
      position: absolute;
      content: '';
      width: 70%;
      padding-top: 50%;
      background-image: url("~@/assets/shadow-danger.svg");
      background-size: cover;
      top: -10%;
      right: 0;
      z-index: -1;
      transform: rotate(-30deg);
      filter: blur(70px);
    }
  }
}
</style>
