<template>
  <div class="home-capability-block">
    <div class="row">
      <div class="col-md-5">
        <header class="home-capability-block__header">
          <base-title class="home-capability-block__title" type="h3">
            <slot name="title"/>
          </base-title>
        </header>
        <div class="home-capability-block__info">
          <slot name="info"/>
        </div>
        <slot name="after"/>
      </div>
      <div class="col-md-7">
        <div class="home-capability-block__image">
          <slot name="image"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../../scss/variables";

.home-capability-block {
  &__image {
    @media (max-width: 1199px) {
      img {
        max-width: 100%;
      }
    }
  }

  &__header {
    margin-right: -10px;
  }

  &__info {
    margin-top: 36px;
    font-size: 24px;
    line-height: 34px;
    margin-right: -2px;

    p {
      margin: $row 0;

      b {
        font-weight: 500;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin: 0;
      padding-left: 20px;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 10px;

        &:before {
          position: absolute;
          left: -15px;
          content: '•';
        }
      }
    }
  }

  @media (max-width: 575px) {
    & > .row {
      gap: 30px;
    }

    &__info {
      font-size: 17px;
      line-height: 22px;
      margin-top: 16px;
    }
  }
}
</style>
<script>
import BaseTitle from '@/components/Base/BaseTitle'

export default {
  components: {BaseTitle},
}
</script>
