<template>
  <home-capability-block class="home-capability-applications">
    <template #title>{{ t(`${baseT}title`) }}</template>
    <template #info>
      <p v-html="t(`${baseT}desc_1`)"/>
      <p>{{ t(`${baseT}desc_2`) }}</p>
    </template>
    <template #image>
      <div class="home-capability-applications__grid">
        <div class="home-capability-applications__image">
          <div class="home-capability-applications__man">
            <img :src="imageApplicationsMan" alt="app man">
          </div>
        </div>
        <HomeCapabilityApps/>
      </div>
    </template>
    <template #after>
      <HomeCapabilityPlatforms class="home-capability-applications__platforms"/>
    </template>
  </home-capability-block>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {provide} from 'vue'
import HomeCapabilityBlock from '@/pages/Home/components/HomeCapabilityBlock'
import HomeCapabilityPlatforms from '@/pages/Home/components/HomeCapabilityPlatforms'
import imageApplicationsMan from '@/assets/capability-app.png'
import HomeCapabilityApps from '@/pages/Home/components/HomeCapabilityApps'

const {t} = useI18n()
const baseT = 'home.capability.applications.'
provide('baseT', baseT)
</script>

<style lang="scss">
.home-capability-applications {
  margin-bottom: -85px;

  &__platforms {
    margin-top: 68px;
  }

  &__grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 187px;

    &:after {
      position: absolute;
      content: '';
      width: 60%;
      padding-top: 40%;
      background-image: url("~@/assets/shadow-primary.svg");
      background-size: cover;
      bottom: 5%;
      left: 5%;
      opacity: .7;
      filter: blur(90px);
      z-index: -1;
    }

    &:before {
      position: absolute;
      content: '';
      width: 80%;
      padding-top: 60%;
      background-image: url("~@/assets/shadow-danger.svg");
      background-size: cover;
      top: -6%;
      right: 0;
      z-index: -1;
      transform: rotate(-30deg);
      filter: blur(60px);
    }
  }

  &__image, &__man {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__man {
    position: relative;
    z-index: 0;

    img {
      max-height: 687px;
    }
  }

  @media (max-width: 575px) {
    margin-bottom: 0;

    & > .row > :last-child {
      display: none;
    }

    &__platforms {
      margin-top: 42px;
    }
  }
}
</style>
