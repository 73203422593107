<template>
  <div :class="['home-page', {'home-page_dark': isDark}]">
    <div class="home-page__background-dark" ref="background"/>
    <div class="home-page__background-violet" ref="backgroundViolet"/>
    <div class="home-page__background-red" ref="backgroundRed"/>
    <HomePromoView/>
    <HomeNoCode class="home-page__no-code"/>
    <div class="home-page__violet" ref="violet">
      <HomeCapabilityView class="home-page__capability"/>
    </div>
    <HomeArchitectureView v-if="false" class="home-page__architecture"/>
    <HomeToolsView class="home-page__tools"/>
    <HomeIntegrationView class="home-page__integration"/>
    <HomeAdvantageView class="home-page__advantage"/>
    <HomeProtocolView class="home-page__protocol"/>
    <HomeSecurityTechnologyView class="home-page__security-technology"/>
    <div class="home-page__dark" ref="dark">
      <HomeScopeView class="home-page__scope"/>
      <HomeConsultationView class="home-page__consultation"/>
      <HomeProjectsView class="home-page__projects"/>
      <HomeExchangerView class="home-page__exchanger"/>
      <HomeDemoView class="home-page__demo"/>
    </div>
    <div class="home-page__red" ref="red">
      <HomeEnterpriseView class="home-page__enterprise"/>
    </div>
    <HomeCustomView class="home-page__custom"/>
  </div>
</template>

<script setup>
import HomePromoView from '@/pages/Home/views/HomePromoView'
import HomeCapabilityView from '@/pages/Home/views/HomeCapabilityView'
import HomeArchitectureView from '@/pages/Home/views/HomeArchitectureView'
import HomeToolsView from '@/pages/Home/views/HomeToolsView'
import HomeAdvantageView from '@/pages/Home/views/HomeAdvantageView'
import HomeSecurityTechnologyView from '@/pages/Home/views/HomeSecurityTechnologyView'
import HomeIntegrationView from '@/pages/Home/views/HomeIntegrationView'
import HomeProjectsView from '@/pages/Home/views/HomeProjectsView'
import HomeExchangerView from '@/pages/Home/views/HomeExchangerView'
import HomeScopeView from '@/pages/Home/views/HomeScopeView'
import HomeDemoView from '@/pages/Home/views/HomeDemoView'
import HomeEnterpriseView from '@/pages/Home/views/HomeEnterpriseView'
import HomeCustomView from '@/pages/Home/views/HomeCustomView'
import HomeConsultationView from '@/pages/Home/views/HomeConsultationView'
import {computed, onMounted, onUnmounted, ref} from 'vue'
import {gsap} from '@/myGsap'
import HomeNoCode from '@/pages/Home/views/HomeNoCode'
import HomeProtocolView from '@/pages/Home/views/HomeProtocolView'
import useCheckout from '@/use/checkout'
import useMeta from '@/use/meta'

const {setTitle, setDescription} = useMeta()
setTitle('Собственный блокчейн без программирования')
setDescription('Блокчейн-конструктор Eralauncher. Создайте  многофункциональный блокчейн под вашим брендом для любого бизнеса и подключите брендированные приложения.')

const dark = ref()
const violet = ref()
const red = ref()
const background = ref()
const backgroundViolet = ref()
const backgroundRed = ref()
const timelineDark = ref()
const timelineViolet = ref()
const timelineRed = ref()

const {addSetGetField} = useCheckout()
const isDark = computed(addSetGetField({base: 'general', action: 'UPDATE_GENERAL', field: 'isDark'}))

const addAnimationBackground = (trigger, target) => {
  if (trigger.value instanceof HTMLElement) {
    return gsap.timeline({
      scrollTrigger: {
        trigger: trigger.value,
        start: 'top center-=200px',
        end: 'bottom center',
        toggleActions: 'play reverse play reset',
        easy: 'none',
        onEnter() {
          trigger.value.setAttribute('data-active', true)
          isDark.value = [...trigger.value.classList].includes('home-page__dark')
        },
        onEnterBack() {
          trigger.value.setAttribute('data-active', true)
          isDark.value = [...trigger.value.classList].includes('home-page__dark')
        },
        onLeave() {
          trigger.value.setAttribute('data-active', false)
          isDark.value = false
        },
        onLeaveBack() {
          trigger.value.setAttribute('data-active', false)
          isDark.value = false
        },
      },
    })
        .timeScale(8)
        .to(target.value, {opacity: 1, ease: 'none'}, 0)
  }
  return null
}

onMounted(() => {
  setTimeout(() => {
    timelineDark.value = addAnimationBackground(dark, background)
    timelineViolet.value = addAnimationBackground(violet, backgroundViolet)
    timelineRed.value = addAnimationBackground(red, backgroundRed)
  }, 1000)
})

onUnmounted(() => {
  if (timelineDark.value) timelineDark.value.kill()
  if (timelineViolet.value) timelineViolet.value.kill()
  if (timelineRed.value) timelineRed.value.kill()
})
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.home-page {
  position: relative;
  margin-top: 72px;

  &__background-dark, &__background-violet, &__background-red {
    position: fixed;
    inset: 0;
    z-index: -1;
    opacity: 0;
    transition: $transition-default;
  }

  &__background-dark {
    background-color: #02071C;
  }

  &__background-violet {
    background: #E5E2FF;
  }

  &__background-red {
    background: $color-danger;
  }

  &_dark {
    color: #E9E9E9;

    .base-title {
      color: white;
    }
  }

  &__dark {
    position: relative;
    --color-title: black;
    --color-text: #171717;

    .base-title {
      color: var(--color-title);
    }

    &[data-active="true"] {
      --color-title: white;
      --color-text: #E9E9E9;
    }

    @media all and (max-width: 767px) {
      img {
        max-width: 100%;
      }
    }
  }

  &__violet {
    position: relative;
  }

  &__red {
    position: relative;

    &[data-active="true"] {
      color: white;

      .base-title {
        color: white;
      }

      .home-enterprise {
        --border-color: white;

        &:after {
          content: '';
        }
      }
    }
  }

  &__no-code {
    margin-top: 125px;
  }

  &__capability {
    margin-top: 102px;
    padding-top: 78px;
    padding-bottom: 110px;

    @media all and (max-width: 767px) {
      margin-top: 70px;
      padding-bottom: 100px;
    }
  }

  &__technology {
    margin-top: 110px;
  }

  &__advantage {
    margin-top: 207px;
  }

  &__architecture {
    margin-top: 110px;
  }

  &__tools {
    margin-top: 130px;
  }

  &__integration {
    margin-top: 207px;
  }

  &__protocol {
    margin-top: 207px;
  }

  &__security-technology {
    padding-top: 212px;
  }

  &__projects {
    margin-top: 220px;
  }

  &__exchanger {
    margin-top: 88px;
  }

  &__scope {
    margin-top: 204px;
  }

  &__demo {
    margin-top: 370px;
    padding-bottom: 255px;
  }

  &__enterprise {
    padding-top: 295px;
    margin-top: -200px;
    padding-bottom: 220px;
  }

  &__custom {
    margin-top: 20px;
    margin-bottom: 184px;
  }

  &__consultation {
    margin-top: 211px;
  }
}

@media (max-width: 767px) {
  .home-page {
    margin-top: 0;

    &__tools {
      margin-top: 94px;
    }

    &__architecture {
      margin-top: 50px;
    }

    &__protocol {
      margin-top: 100px;
    }

    &__advantage {
      margin-top: 94px;
    }

    &__create {
      margin-top: 94px;
      margin-bottom: -150px;
    }

    &__security-technology {
      padding-top: 130px;
    }

    &__integration {
      margin-top: 94px;
    }

    &__projects {
      margin-top: 94px;
    }

    &__exchanger {
      margin-top: 73px;
    }

    &__scope {
      margin-top: 94px;
    }

    &__demo {
      margin-top: 94px;
      padding-bottom: 100px;
    }

    &__enterprise {
      margin-top: 94px;
      padding-top: 100px;
      padding-bottom: 100px;
    }

    &__custom {
      margin-top: 94px;
      margin-bottom: 100px;
    }

    &__config {
      padding-top: 220px;
      padding-bottom: 94px;
    }

    &__consultation {
      margin-top: 94px;
    }
  }
}

@media (max-width: 575px) {
  .home-page__no-code {
    margin-top: 72px;
  }

  .home-page__capability {
    margin-top: 35px;
    padding-top: 34px;
    padding-bottom: 62px;
  }

  .home-page__tools {
    margin-top: 61px;
  }

  .home-page__integration {
    margin-top: 68px;
  }

  .home-page__advantage {
    margin-top: 80px;
  }

  .home-page__protocol {
    margin-top: 80px;
  }

  .home-page__security-technology {
    padding-top: 80px;
  }

  .home-page__scope {
    margin-top: 42px;
  }

  .home-page__projects {
    margin-top: 76px;
  }

  .home-page__exchanger {
    margin-top: 46px;
  }

  .home-page__demo {
    margin-top: 94px;
    padding-bottom: 60px;
  }

  .home-page__enterprise {
    margin-top: 0;
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .home-page__custom {
    margin-bottom: 70px;
    margin-top: 40px;
  }
}
</style>
