<template>
  <div class="constructor-page">
    <ConstructorPromoView/>
    <ConstructorHowView class="constructor-page__how"/>
    <ConstructorStepOne @save="save" @reset="reset" class="constructor-page__step-one"/>
    <ConstructorStepTwo class="constructor-page__step-two"/>
    <ConstructorStepThree class="constructor-page__step-three"/>
    <ConstructorStepFour class="constructor-page__step-four"/>
    <ConstructorCheckoutView class="constructor-page__checkout" @save="save"/>
  </div>
  <teleport to="body">
    <base-modal-message v-if="openFieldsError" @close="closeModalFields">
      {{ t(`${baseT}message_fields_error`) }}
    </base-modal-message>
    <base-modal-message v-if="openReset" @close="closeModalReset">
      {{ t(`${baseT}message_reset`) }}
    </base-modal-message>
    <base-modal-alert v-if="openSuccess" @close="closeModalSuccess">
      <p>{{ t(`${baseT}message_success_1`) }}</p>
      <p>{{ t(`${baseT}message_success_2`) }}</p>
    </base-modal-alert>
  </teleport>
</template>

<script setup>
import ConstructorPromoView from '@/pages/Constructor/views/ConstructorPromoView'
import ConstructorHowView from '@/pages/Constructor/views/ConstructorHowView'
import ConstructorStepOne from '@/pages/Constructor/views/ConstructorStepOne'
import ConstructorStepTwo from '@/pages/Constructor/views/ConstructorStepTwo'
import ConstructorStepThree from '@/pages/Constructor/views/ConstructorStepThree'
import ConstructorStepFour from '@/pages/Constructor/views/ConstructorStepFour'
import ConstructorCheckoutView from '@/pages/Constructor/views/ConstructorCheckoutView'
import {provide, ref} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex'
import VueScrollTo from 'vue-scrollto'
import BaseModalMessage from '@/components/Base/Modal/BaseModalMessage'
import BaseModalAlert from '@/components/Base/Modal/BaseModalAlert'
import {useI18n} from 'vue-i18n'
import useMeta from '@/use/meta'

const {setTitle, setDescription} = useMeta()
setTitle('Eralauncher. Блокчейн-конструктор')
setDescription('Платформа для создания собственного независимого блокчейна без разработки. Подключите готовые блокчейн-приложения под вашим брендом.')

const {query: {openForm}} = useRoute()
const store = useStore()
const {t} = useI18n()
const baseT = 'constructor.'

const saveForm = ref(false)
const openFieldsError = ref(false)
const openReset = ref(false)
const openSuccess = ref(false)

const closeModal = openStatus => {
  openStatus.value = false
}

const closeModalFields = () => {
  VueScrollTo.scrollTo('#step-one')
  closeModal(openFieldsError)
}

const closeModalReset = () => {
  VueScrollTo.scrollTo('#step-one')
  closeModal(openReset)
}

const closeModalSuccess = () => {
  closeModal(openSuccess)
}

const save = () => {
  saveForm.value = true

  const required = [
    store.state.general.name,
    store.state.emission.investTokenName,
    store.state.emission.investTokenImage,
    store.state.emission.fuelTokenName,
    store.state.emission.fuelTokenImage,
    store.state.stake.APYByYear,
    store.state.stake.fuelTokenByYear,
  ]
  if (!required.every(r => !!r)) {
    return openFieldsError.value = true
  }

  openSuccess.value = true

  store.dispatch('saveConfig')
  store.dispatch('cart/updateCart')
}

const reset = () => {
  openReset.value = true
  store.dispatch('resetConfig')
  saveForm.value = false

  setTimeout(() => {
    store.commit('updateKeyComponents')
  }, 500)
}

provide('openForm', openForm)
provide('saveForm', saveForm)
</script>

<style lang="scss" scoped>
.constructor-page {
  overflow: hidden;

  @media (min-width: 992px) {
    padding-top: 69px;
    margin-top: -69px;
  }

  &__disabled {
    position: relative;
    overflow: hidden;

    &_active {
      &:after {
        position: absolute;
        inset: 0;
        content: '';
        background-color: rgba(0, 0, 0, .5);
        z-index: 1;
      }
    }
  }

  &__how {
    margin-top: -125px;

    @media all and (max-width: 767px) {
      margin-top: -90px;
    }
  }

  &__step-one {
    margin-top: 166px;
    margin-bottom: 84px;
  }

  &__step-two {
    margin-top: 84px;
  }

  &__step-three {
    margin-top: 166px;
  }

  &__step-four {
    margin-top: 166px;
  }

  &__checkout {
    margin-top: 166px;
    margin-bottom: 173px;
  }
}
</style>
