<template>
  <div class="web-interface">
    <header class="web-interface__header">
      <h3 class="web-interface__title">{{ t(`${baseT}label`) }}</h3>
      <img class="web-interface__arrow" :src="arrowInterfaceIcon" alt="Arrow">
    </header>
    <div class="web-interface__window">
      <ConstructorWebInterfaceSidebar class="web-interface__sidebar"/>
      <ConstructorWebInterfaceTopPanel/>
      <ConstructorWebInterfaceMain/>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex'
import {provide, toRefs} from 'vue'
import {useI18n} from 'vue-i18n'
import arrowInterfaceIcon from '@/assets/arrow-interface.svg'
import ConstructorWebInterfaceSidebar from './ConstructorWebInterfaceSidebar'
import ConstructorWebInterfaceTopPanel from './ConstructorWebInterfaceTopPanel'
import ConstructorWebInterfaceMain from './ConstructorWebInterfaceMain'

const {t} = useI18n()
const baseT = 'constructor.steps.two.interface.'
const store = useStore()
const {colorSidebar, colorSidebarMenu, colorButton, colorLink} = toRefs(store.state.style)

provide('baseT', baseT)
provide('colorSidebar', colorSidebar)
provide('colorSidebarMenu', colorSidebarMenu)
provide('colorButton', colorButton)
provide('colorLink', colorLink)
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";

.web-interface {
  &__header {
    padding-bottom: 30px;
  }

  &__title {
    position: relative;
    display: inline-block;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: $border-color;
    margin: 0;
  }

  &__arrow {
    position: relative;
    top: 10px;
    left: 10px;
  }

  &__window {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    border: 1px solid $color-primary;
    background-color: #EAEAEA;
    border-radius: 9px;
  }

  &__sidebar {
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

@media (max-width: 767px) {
  .web-interface__window {
    overflow-x: scroll;
  }
}
</style>
