<template>
  <div class="home-tools-slide">
    <div v-if="$slots.icon" class="home-tools-slide__icon">
      <slot name="icon"/>
    </div>
    <div v-if="$slots.title" class="home-tools-slide__title">
      <slot name="title"/>
    </div>
    <div v-if="$slots.desc" class="home-tools-slide__desc">
      <slot name="desc"/>
    </div>
    <div v-if="$slots.image" class="home-tools-slide__image">
      <slot name="image"/>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/scss/variables.scss";

.home-tools-slide {
  color: $color-text;

  &__icon {
    height: 56px;
    width: auto;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    margin-top: 10px;
  }

  &__desc {
    margin-top: 20px;
    font-size: 18px;
    line-height: 26px;
  }

  &__image {
    margin-top: 20px;

    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
